﻿/**
 *  @ngdoc Resource
 *  @name VV Order Resource
 *  @description Application Service for VV Order
 * <h2>Dependencies</h2>
 * "$resource", "appSettings"
*/
(function () {
    "use strict";
    angular.module("common.services")
        .factory("vvOrderResource",
            ["$resource", "appSettings", vvOrderResource]);
    function vvOrderResource($resource, appSettings) {
        return $resource(appSettings.serverPath + "api/vvorders/:orderId", { id: "@id" },
            {
                'getVVOrderById': {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/vvorders/getVVOrderById'
                },
                //'vvOrdersOnPagination':
                //{
                //    method: 'GET',
                //    isArray: true,
                //    url: appSettings.serverPath + 'api/vvorders/vvOrdersOnPagination/:skipSize/:takeSize'
                //},
                'getPracticeDetailByIdForVibrantVue':
                {
                    method: 'GET',
                    isArray: false,
                    url: appSettings.serverPath + 'api/practice/getPracticeDetailByIdForVibrantVue/:practiceId'

                },
                'checkEmailCustomerPersonnel':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/practice/checkEmailCustomerPersonnel/',
                    noLoader: true
                },
                'saveCustomerPersonnel':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/practice/childpractice'
                },
                'getUserName': {
                    method: 'GET',
                    isArray: false,
                    url: appSettings.serverPath + 'api/practice/getUserName/userName',
                    noLoader: true
                },
                'getPracticeUsers':
                {
                    method: 'GET',
                    isArray: true,
                    url: appSettings.serverPath + 'api/practice/getPracticeUsers/practiceId'
                },
                'getShippingRatesByUsingPackage':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/shipengine/getShippingRatesByUsingPackage'
                },
                'shippingAddressDetails':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/addressController/address'
                },
                'shippingAddress':
                {
                    method: 'GET',
                    isArray: true,
                    url: appSettings.serverPath + 'api/addressController/shippingAddress/:practiceId'
                },
                'addressVarification':
                {
                    method: 'POST',
                    isArray: true,
                    url: appSettings.serverPath + 'api/shipengine/addressVarification',
                }, 'patientAddress':
                {
                    method: 'GET',
                    isArray: true,
                    url: appSettings.serverPath + 'api/addressController/patientAddress/:practiceId'
                },
                'getAddressById':
                {
                    method: 'GET',
                    isArray: false,
                    url: appSettings.serverPath + 'api/addressController/getAddressById/:id'
                },
                'saveVvOrder':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/vvorders/saveVvOrder'
                },
                'vvOrderSearch':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/vvorders/vvOrderSearch'
                },
                'updateVVOrder':
                {
                    method: 'PUT',
                    isArray: false,
                    url: appSettings.serverPath + 'api/vvorders/updateVVOrder'
                },
                'getPreferenceByPracticeUserId': {
                    method: 'GET',
                    isArray: false,
                    url: appSettings.serverPath + 'api/orders/getPreferenceByPracticeUserId/:practiceUserId/:isLatitude/:isFromCust'
                },
                'getDateTime':
                {
                    method: 'GET',
                    isArray: false,
                    url: appSettings.serverPath + 'api/practice/getDateTime'
                },
                "getAvailableDiscountsOnUser":
                {
                    method: "GET",
                    isArray: false,
                    url: appSettings.serverPath + 'api/discounts/getAvailableDiscountsOnUser/:userPracticeId/:isSpareLens'
                },
                'addDiscountOnUser':
                {
                    method: 'POST',
                    isArray: false,
                    url: appSettings.serverPath + 'api/practice/addDiscountOnUser'
                },
                "getAvailableDiscountsOnOrder":
                {
                    method: "GET",
                    isArray: false,
                    url: appSettings.serverPath + 'api/discounts/getAvailableDiscountsOnOrder/:practiceUserId/:orderType/:isSpareLens'
                },
            })
    }
}())